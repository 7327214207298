import {
    CLEAR_TENDER_REQUEST,
    CLEAR_TENDER_REQUEST_ITEM,
    CREATE_TENDER_REQUEST_ITEM,
    CREATE_TENDER_REQUEST_ITEM_FROM_CSV,
    CREATE_TENDER_REQUEST_ITEM_OFFER,
    DELETE_TENDER_REQUEST_ITEM,
    DELETE_TENDER_REQUEST_ITEM_OFFER,
    GET_TENDER_REQUEST_BY_ID,
    GET_TENDER_REQUEST_ITEM_OFFERS,
    GET_TENDER_REQUESTS,
    REOPEN_TENDER_REQUEST,
    SAVE_TENDER_REQUEST,
    SEND_TENDER_REQUEST,
    SET_TENDER_REQUEST_ITEM,
    UPDATE_TENDER_REQUEST_ITEM,
    UPDATE_TENDER_REQUEST_ITEM_OFFER,
    UPLOAD_TENDER_REQUEST_ITEM_IMAGE
} from "../actions/types";

export const TenderRequestStatus = {
    DRAFT: 'DRAFT',
    PENDING: 'PENDING',
    OPEN: 'OPEN',
    CLOSED: 'CLOSED'
}

export const emptyTenderRequest = {
    id: null,
    status: null,
    created_at: null,
    requested_by: null,
    department: null,
    delivery_location: null,
    reference_number: null,
    cost_center: null,
    project_name: null,
    project_part: null,
    suppliers: [],
    additional_suppliers: [],
    selected_suppliers: [],
    items: []
}

export const emptyTenderRequestItem = {
    id: null,
    tender_request: null,
    ean: null,
    article_number: null,
    manufacturer_name: null,
    model_number: null,
    quantity: null,
    description: null,
    image_count: 0,
    offers_count: 0
}

const initialState = {
    tenderRequests: {
        items: [],
        totalCount: 0
    },
    currentTenderRequest: emptyTenderRequest,
    currentTenderRequestItem: emptyTenderRequestItem,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TENDER_REQUESTS:
            return {
                ...state,
                tenderRequests: {
                    items: action.payload.results,
                    totalCount: action.payload.count
                }
            };
        case GET_TENDER_REQUEST_BY_ID:
            return {
                ...state,
                currentTenderRequest: {...action.payload}
            };
        case SAVE_TENDER_REQUEST:
            return {
                ...state,
                currentTenderRequest: action.payload
            };
        case CLEAR_TENDER_REQUEST:
            return {
                ...state,
                currentTenderRequest: emptyTenderRequest
            };
        case SEND_TENDER_REQUEST:
            return {
                ...state,
                currentTenderRequest: action.payload
            };
        case REOPEN_TENDER_REQUEST:
            return {
                ...state,
                currentTenderRequest: action.payload
            };
        case CREATE_TENDER_REQUEST_ITEM:
            return {
                ...state,
                currentTenderRequest: {
                    ...state.currentTenderRequest,
                    items: [...state.currentTenderRequest.items, action.payload]
                },
            };
        case UPDATE_TENDER_REQUEST_ITEM:
            return {
                ...state,
                currentTenderRequest: {
                    ...state.currentTenderRequest,
                    items: state.currentTenderRequest.items.map(item => item.id === action.payload.id ? action.payload : item)
                },
                currentTenderRequestItem:emptyTenderRequestItem
            };
        case DELETE_TENDER_REQUEST_ITEM:
            return {
                ...state,
                currentTenderRequest: {
                    ...state.currentTenderRequest,
                    items: state.currentTenderRequest.items.filter(item => item.id !== action.payload)
                },
                currentTenderRequestItem: emptyTenderRequestItem
            };
        case SET_TENDER_REQUEST_ITEM:
            return {
                ...state,
                currentTenderRequestItem: action.payload
            };
        case CLEAR_TENDER_REQUEST_ITEM:
            return {
                ...state,
                currentTenderRequestItem: emptyTenderRequestItem
            };
        case UPLOAD_TENDER_REQUEST_ITEM_IMAGE:
            return {
                ...state,
                currentTenderRequest: {
                    ...state.currentTenderRequest,
                    items: state.currentTenderRequest.items.map(item => item.id === action.payload.id ? {
                        ...item, image_count: action.payload.image_count
                    } : item)
                },
                currentTenderRequestItem: {
                    ...state.currentTenderRequestItem,
                    image_count: action.payload.image_count
                }
            };
        case CREATE_TENDER_REQUEST_ITEM_FROM_CSV:
            return {
                ...state,
                currentTenderRequest: {
                    ...state.currentTenderRequest,
                    items: [...state.currentTenderRequest.items, ...action.payload]
                },
            };
        case CREATE_TENDER_REQUEST_ITEM_OFFER:
            return {
                ...state,
                currentTenderRequest: {
                    ...state.currentTenderRequest,
                    items: state.currentTenderRequest.items.map(item => {
                        if (item.id === action.payload.tender_request_item) {
                            return {
                                ...item,
                                offers: {
                                    items: [...item.offers.items, action.payload],
                                    totalCount: item.offers.totalCount + 1
                                }
                            }
                        }
                        return item;
                    })
                }
            };
        case UPDATE_TENDER_REQUEST_ITEM_OFFER:
            return {
                ...state,
                currentTenderRequest: {
                    ...state.currentTenderRequest,
                    items: state.currentTenderRequest.items.map(item => {
                        if (item.id === action.payload.tender_request_item) {
                            return {
                                ...item,
                                offers: {
                                    items: item.offers.items.map(offer => offer.id === action.payload.id ? action.payload : offer),
                                    totalCount: item.offers.totalCount + 1
                                }
                            }
                        }
                        return item;
                    })
                }
            };
        case DELETE_TENDER_REQUEST_ITEM_OFFER:
            return {
                ...state,
                currentTenderRequest: {
                    ...state.currentTenderRequest,
                    items: state.currentTenderRequest.items.map(item => {
                        if (item.id === action.payload.tenderRequestItemId) {
                            return {
                                ...item,
                                offers: {
                                    items: item.offers.items.filter(offer => offer.id !== action.payload.id),
                                    totalCount: item.offers.totalCount - 1
                                },
                                offers_count: item.offers_count - 1
                            }
                        }
                        return item;
                    })
                }
            };
        case GET_TENDER_REQUEST_ITEM_OFFERS:
            return {
                ...state,
                currentTenderRequest: {
                    ...state.currentTenderRequest,
                    items: state.currentTenderRequest.items.map(item => {
                        if (item.id === action.payload.tenderRequestItemId) {
                            return {
                                ...item,
                                offers: {
                                    items: action.payload.offers.results,
                                    totalCount: action.payload.offers.count
                                }
                            }
                        }
                        return item;
                    })
                }
            };
        default:
            return state;
    }
}